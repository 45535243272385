import React from "react";
import * as Icon from "react-feather";

const adminMenuConfig = [
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: <Icon.Home size={16} />,
    navLink: "/",
  },
  {
    id: "jobvacancy",
    title: "Job Vacancy",
    type: "item",
    icon: <Icon.Grid size={16} />,
    navLink: "/job-vacancy-setting",
  }
]

export default adminMenuConfig;
